<template>
  <div>
    <BackgroundLayer class="fixed z-30" v-if="showOverlay" @click.native="closeDialogs"/>
    <div :class="['nav--filter--container', navFilter]" ref="filter">
      <Navbar mode="solid" :class="[navbar, 'nav--bar']"/>
      <div class="filter-container">
        <div class="filter-wrapper">
          <SelectDropdown 
            @input="setCountry" 
            ref="countrySelect" 
            :show-icon="true" 
            class="dropdown"
            :options-list="lang === 'en' ? countriesEn : countriesFr"
            :description="$t('common.choose_a_country')"
          />
        </div>
      </div>
    </div>
    <main class="main">
      <div class="main-wrapper">
        <h1 class="title" ref="pageTitle" v-if="filteredTours.length > 0 && !loading">
          {{ $tc("page.group_tours.title", filteredTours.length) }}
        </h1>
        <EmptyState v-if="filteredTours.length < 1 && !loading" class="mt-4" icon-class="bx bx-trip" :title="
											$t('page.group_tours.empty_state.title', {
												country: lang === 'fr' ? frPreposition : country
											})
										" :text="
													$t('page.group_tours.empty_state.text', {
														country: lang === 'fr' ? frPreposition : country
													})
												"/>
        <div class="tours-container" v-if="loading">
          <div class="tour--skeleton">
            <div class="tour--skeleton--img"></div>
            <div class="tour--skeleton--title"></div>
            <div class="tour--skeleton--review"></div>
            <div class="tour--skeleton--price"></div>
          </div>
          <div class="tour--skeleton">
            <div class="tour--skeleton--img"></div>
            <div class="tour--skeleton--title"></div>
            <div class="tour--skeleton--review"></div>
            <div class="tour--skeleton--price"></div>
          </div>
          <div class="tour--skeleton">
            <div class="tour--skeleton--img"></div>
            <div class="tour--skeleton--title"></div>
            <div class="tour--skeleton--review"></div>
            <div class="tour--skeleton--price"></div>
          </div>
          <div class="tour--skeleton">
            <div class="tour--skeleton--img"></div>
            <div class="tour--skeleton--title"></div>
            <div class="tour--skeleton--review"></div>
            <div class="tour--skeleton--price"></div>
          </div>
          <div class="tour--skeleton">
            <div class="tour--skeleton--img"></div>
            <div class="tour--skeleton--title"></div>
            <div class="tour--skeleton--review"></div>
            <div class="tour--skeleton--price"></div>
          </div>
          <div class="tour--skeleton">
            <div class="tour--skeleton--img"></div>
            <div class="tour--skeleton--title"></div>
            <div class="tour--skeleton--review"></div>
            <div class="tour--skeleton--price"></div>
          </div>
          <div class="tour--skeleton">
            <div class="tour--skeleton--img"></div>
            <div class="tour--skeleton--title"></div>
            <div class="tour--skeleton--review"></div>
            <div class="tour--skeleton--price"></div>
          </div>
          <div class="tour--skeleton">
            <div class="tour--skeleton--img"></div>
            <div class="tour--skeleton--title"></div>
            <div class="tour--skeleton--review"></div>
            <div class="tour--skeleton--price"></div>
          </div>
          <div class="tour--skeleton">
            <div class="tour--skeleton--img"></div>
            <div class="tour--skeleton--title"></div>
            <div class="tour--skeleton--review"></div>
            <div class="tour--skeleton--price"></div>
          </div>
          <div class="tour--skeleton">
            <div class="tour--skeleton--img"></div>
            <div class="tour--skeleton--title"></div>
            <div class="tour--skeleton--review"></div>
            <div class="tour--skeleton--price"></div>
          </div>
          <div class="tour--skeleton">
            <div class="tour--skeleton--img"></div>
            <div class="tour--skeleton--title"></div>
            <div class="tour--skeleton--review"></div>
            <div class="tour--skeleton--price"></div>
          </div>
          <div class="tour--skeleton">
            <div class="tour--skeleton--img"></div>
            <div class="tour--skeleton--title"></div>
            <div class="tour--skeleton--review"></div>
            <div class="tour--skeleton--price"></div>
          </div>
        </div>
        <div v-if="filteredTours.length > 0 && !loading" class="tours-container">
          <router-link :to="`/tour?id=${tour.id}`" class="tour-card" v-for="tour in filteredTours" :key="tour.id">
            <figure class="tour-card-img-container">
              <img class="tour-card-img" :src="
																	tour.image
																		? env_path + tour.image
																		: fallBackImg
																" :alt="tour.title"/>
            </figure>
            <div class="tour-card-text-container">
              <h3 class="tour-card-title">
                {{ tour.title }} · {{ lang === 'fr' ? tour.destination_fr : tour.destination }}
              </h3>
              <div class="tour-card-rating">
                <Rating size="xs" :is-static="true" :rating="tour.partner.reviews.rating"/>
                <span>
									{{
                    $tc(
                        "reviews.n_reviews",
                        tour.partner.reviews.count
                    )
                  }}
								</span>
              </div>
              <p class="tour-card-details">
								<span class="tour-card-price">{{ $t("common.from") }}
									{{
                    tour.shared_room_price
                        | currency(
                        tour.currency,
                        getCurrency,
                        rates[
                            `${tour.currency}_${getCurrency}`
                            ]
                        )
                  }}</span>
                ·
                <span class="tour-card-duration">{{
                    $tc("offer.days", tour.duration)
                  }}</span>
              </p>
              <span class="tour-card-agency">
								{{ $t("common.offered_by", {agency: setGenitive(tour.partner.first_name)}) }}
							</span>
            </div>
          </router-link>
        </div>
      </div>
    </main>
    <Footer class="pt-32"/>
    <BottomBar class="layout-bar"/>
  </div>
</template>
<script>
import BottomBar from "@/components/layout/BottomBar.vue";
import Navbar from "@/components/layout/Navbar";
import SelectDropdown from "@/components/dropdowns/SelectDropdown";
import Rating from "@/components/review/Rating";
import Footer from "@/components/layout/Footer";
import EmptyState from "@/components/EmptyState.vue";
import api from "../../../api";
import BackgroundLayer from "@/components/BackgroundLayer.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Tours",
  components: {
    BottomBar,
    Navbar,
    SelectDropdown,
    Rating,
    Footer,
    EmptyState,
    BackgroundLayer,
  },
  metaInfo: {
    title: "Group tours to Africa | Travel Afrique",
  },
  data() {
    return {
      navbar: "nav--default",
      navFilter: "nav--filter--default",
      showFilter: false,
      showOverlay: false,
      showSorter: false,
      lang: localStorage.getItem("lang"),
      countriesEn: [
        {value: "All countries", name: "All"},
        {value: "Benin", name: "Benin"},
        {value: "Cape Verde", name: "Cape Verde"},
        {value: "Côte d'Ivoire", name: "Côte d'Ivoire"},
        {
          value: "Democratic Republic of the Congo",
          name: "Democratic Republic of the Congo",
        },
        {value: "Ghana", name: "Ghana"},
        {value: "Kenya", name: "Kenya"},
        {value: "Namibia", name: "Namibia"},
        { value: "Madagascar", name: "Madagascar"},
        {value: "Morocco", name: "Morocco"},
        {value: "Rwanda", name: "Rwanda"},
        {value: "Senegal", name: "Senegal"},
        {value: "South Africa", name: "South Africa"},
        {value: "Tanzania", name: "Tanzania"},
      ],
      countriesFr: [
        {value: "All countries", name: "Tous"},
        {value: "South Africa", name: "Afrique du Sud"},
        {value: "Benin", name: "Bénin"},
        {value: "Cape Verde", name: "Cap-Vert"},
        {value: "Côte d'Ivoire", name: "Côte d'Ivoire"},
        {value: "Ghana", name: "Ghana"},
        {value: "Kenya", name: "Kenya"},
        { value: "Namibia", name: "Namibie" },
        { value: "Madagascar", name: "Madagascar" },
        {value: "Morocco", name: "Maroc"},
        {
          value: "Democratic Republic of the Congo",
          name: "République démocratique du Congo",
        },
        {value: "Rwanda", name: "Rwanda"},
        {value: "Senegal", name: "Sénégal"},
        {value: "Tanzania", name: "Tanzanie"},
      ],
      countrySlugs: {
        benin: {
          en: "Benin",
          fr: "Benin",
        },
        "cape-verde": {
          en: "Cape Verde",
          fr: "Cap-Vert",
        },
        "cote-d-ivoire": {
          en: "Côte d'Ivoire",
          fr: "Côte d'Ivoire",
        },
        "democratic-republic-of-the-congo": {
          en: "Democratic Republic of the Congo",
          fr: "République démocratique du Congo",
        },
        ghana: {
          en: "Ghana",
          fr: "Ghana",
        },
        kenya: {
          en: "Kenya",
          fr: "Kenya",
        },
        madagascar: {
          en: "Madagascar",
          fr: "Madagascar",
        },
        morocco: {
          en: "Morocco",
          fr: "Maroc",
        },
        namibia: {
          en: "Namibia",
          fr: "Namibie",
        },
        rwanda: {
          en: "Rwanda",
          fr: "Rwanda",
        },
        senegal: {
          en: "Senegal",
          fr: "Sénégal",
        },
        "south-africa": {
          en: "South Africa",
          fr: "Afrique du Sud",
        },
        tanzania: {
          en: "Tanzania",
          fr: "Tanzanie",
        },
      },
      currencies: [
        {name: "USD – $", value: "USD"},
        {name: "CHF – ₣", value: "CHF"},
        {name: "EUR – €", value: "EUR"},
      ],
      filteredTours: [],
      tours: [],
      country: "",
      loading: true,
      landed: false,
      fallBackImg: require("../../../assets/img/placeholders/placeholder.png"),
    };
  },
  watch: {
    $route(newValue) {
      let currentCountry = newValue.query.q
          ? newValue.query.q
          : "All countries";

      if (currentCountry === "All countries") {
        this.filteredTours = this.tours;
        this.country = "All countries";
      } else {
        this.filteredTours = this.tours.filter(
            (tour) => tour.destination === currentCountry
        );
        this.country = this.getCountryNormalName();
      }

      let select =
          this.$refs["countrySelect"].$el.children[0].children[1];
      select.value = currentCountry;
    },
  },
  methods: {
    ...mapActions({
      setCurrencyRates: "exchange/setCurrencyRates",
      setFixedCurrencyRates: "exchange/setFixedCurrencyRates",
    }),
    setFixedRates() {
      this.setFixedCurrencyRates(this.rates);
    },
    setGenitive(name) {
      return this.$options.filters.agencyGenitive(name);
    },
    setCountry(country) {
      this.loading = true;
      if (country !== "All countries") {
        let destinationFr = this.countriesFr.filter(
            (destination) => destination.value === country
        );
        let destinationEn = this.countriesEn.filter(
            (destination) => destination.value === country
        );
        let destination =
            this.lang === "fr" ? destinationFr[0] : destinationEn[0];
        this.$router.push({
          path: "/tours",
          query: {
            q: destination.value,
            country: destination.name,
            lang: localStorage.getItem("lang"),
          },
        });
      } else {
        this.loading = true;
        this.$router.push({
          path: "/tours",
          query: {lang: localStorage.getItem("lang")},
        });
      }
      this.loading = false;
    },
    async toggleNavBar() {
      if(this.$refs["pageTitle"]){
        let titleTop = this.$refs["pageTitle"].getBoundingClientRect().top;
        if (titleTop > 0) {
          this.navbar = "nav--default";
          this.navFilter = "nav--filter--default";
        } else {
          this.navbar = "nav--hidden";
          this.navFilter = "nav--filter--top";
        }
      }
    },
    getCurrentCountry() {
      let currentCountry = this.$route.query.q;
      return currentCountry
          ? currentCountry.charAt(0).toUpperCase() +
          currentCountry.slice(1)
          : "All countries";
    },
    getCountryNormalName() {
      let currentCountry = this.$route.query.country;
      return currentCountry ? currentCountry : "All countries";
    },
  },
  async created() {
    window.addEventListener("scroll", await this.toggleNavBar);
  },
  destroyed() {
    window.removeEventListener("scroll", this.toggleNavBar);
  },
  computed: {
    ...mapGetters({
      getCurrency: "global/currency",
      rates: "exchange/rates",
    }),
    env_path() {
      return process.env["VUE_APP_FILE_BASE_PATH"];
    },
    frPreposition() {
      let prepositions = {
        Benin: "au Bénin",
        "Cape Verde": "au Cap-Vert",
        "Côte d'Ivoire": "en Côte d'Ivoire",
        "Democratic Republic of the Congo":
            "en République démocratique du Congo",
        Ghana: "au Ghana",
        Kenya: "en Kenya",
        Madagascar: "à Madagascar",
        Morocco: "au Maroc",
        Namibia: "en Namibie",
        Rwanda: "au Rwanda",
        Senegal: "au Sénégal",
        "South Africa": "en Afrique du Sud",
        Tanzania: "en Tanzanie",
      };
      let country = prepositions[this.$route.query.q];
      return country;
    },
  },
  async mounted() {
    await this.setCurrencyRates();
    let currentCountry = this.getCurrentCountry();
    let select = this.$refs["countrySelect"].$el.children[0].children[1];
    select.value = currentCountry;
    this.country = currentCountry;

    await api.getAllTours().then(async (res) => {
      this.tours = await res.data.tours;
    });

    if (currentCountry === "All countries") {
      this.filteredTours = this.tours;
    } else {
      this.filteredTours = this.tours.filter(
          (tour) => tour.destination === currentCountry
      );
    }

    this.setFixedRates();
    this.loading = false;
  },
};
</script>
<style scoped>
.nav--bar {
  @apply fixed;
}

.nav--default {
  @apply top-0;
}

.nav--hidden {
  @apply -top-full;
}

.nav--filter--container {
  @apply z-20 fixed w-full left-0 bg-white;
}

.nav--filter--default {
  @apply top-20;
}

.nav--filter--top {
  @apply top-0;
}

.filter-container {
  @apply px-4;
}

.filter-wrapper {
  @apply w-full max-w-screen-xl md:max-w-screen-md py-3;
  @apply relative transform -translate-x-1/2 left-1/2;
}

.main {
  @apply px-4 pt-40;
}

.main-wrapper {
  @apply w-full max-w-screen-xl md:max-w-screen-md py-4;
  @apply relative transform -translate-x-1/2 left-1/2;
}

.dropdown {
  @apply col-span-8;
}

.button {
  @apply col-span-2;
}

.title {
  @apply text-black-base font-semibold text-sm;
}

.tours-container {
  @apply pt-4;
  @apply grid grid-cols-12 gap-y-8 sm:gap-x-10;
}

.tour-card {
  @apply col-span-12 sm:col-span-6 md:col-span-4;
}

.tour-card-text-container {
  @apply pt-3;
}

.tour-card-img-container {
  @apply rounded-md overflow-hidden;
  @apply w-full h-0 pb-56 sm:pb-64 relative;
}

.tour-card-img {
  @apply absolute left-0 top-0;
  @apply object-cover w-full h-full;
}

.tour-card-title {
  @apply font-semibold text-black-base text-base;
}

.tour-card-details {
  @apply text-sm text-black-lighter mt-2;
}

.tour-card-rating {
  @apply flex gap-1.5 items-center;
}

.tour-card-rating span {
  @apply text-sm;
}

.tour-card-price {
  @apply font-semibold text-black-base;
}

.tour-card-agency {
  @apply text-sm text-black-lighter;
  @apply flex items-center gap-1.5;
}

.tour--skeleton {
  @apply col-span-12 sm:col-span-6 md:col-span-4;
  @apply animate-pulse;
}

.tour--skeleton--img {
  @apply rounded-md bg-grey-base;
  @apply w-full h-0 pb-56 sm:pb-64 relative;
  @apply w-full;
}

.tour--skeleton--title {
  @apply h-6 w-64 bg-grey-base mt-3;
}

.tour--skeleton--review {
  @apply h-6 w-44 bg-grey-base mt-1;
}

.tour--skeleton--price {
  @apply h-5 w-44 bg-grey-base mt-2;
}

.layout-bar {
  @apply fixed bottom-0 w-full md:hidden;
}
</style>