var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showOverlay)?_c('BackgroundLayer',{staticClass:"fixed z-30",nativeOn:{"click":function($event){return _vm.closeDialogs.apply(null, arguments)}}}):_vm._e(),_c('div',{ref:"filter",class:['nav--filter--container', _vm.navFilter]},[_c('Navbar',{class:[_vm.navbar, 'nav--bar'],attrs:{"mode":"solid"}}),_c('div',{staticClass:"filter-container"},[_c('div',{staticClass:"filter-wrapper"},[_c('SelectDropdown',{ref:"countrySelect",staticClass:"dropdown",attrs:{"show-icon":true,"options-list":_vm.lang === 'en' ? _vm.countriesEn : _vm.countriesFr,"description":_vm.$t('common.choose_a_country')},on:{"input":_vm.setCountry}})],1)])],1),_c('main',{staticClass:"main"},[_c('div',{staticClass:"main-wrapper"},[(_vm.filteredTours.length > 0 && !_vm.loading)?_c('h1',{ref:"pageTitle",staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$tc("page.group_tours.title", _vm.filteredTours.length))+" ")]):_vm._e(),(_vm.filteredTours.length < 1 && !_vm.loading)?_c('EmptyState',{staticClass:"mt-4",attrs:{"icon-class":"bx bx-trip","title":_vm.$t('page.group_tours.empty_state.title', {
												country: _vm.lang === 'fr' ? _vm.frPreposition : _vm.country
											}),"text":_vm.$t('page.group_tours.empty_state.text', {
														country: _vm.lang === 'fr' ? _vm.frPreposition : _vm.country
													})}}):_vm._e(),(_vm.loading)?_c('div',{staticClass:"tours-container"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)]):_vm._e(),(_vm.filteredTours.length > 0 && !_vm.loading)?_c('div',{staticClass:"tours-container"},_vm._l((_vm.filteredTours),function(tour){return _c('router-link',{key:tour.id,staticClass:"tour-card",attrs:{"to":`/tour?id=${tour.id}`}},[_c('figure',{staticClass:"tour-card-img-container"},[_c('img',{staticClass:"tour-card-img",attrs:{"src":tour.image
																		? _vm.env_path + tour.image
																		: _vm.fallBackImg,"alt":tour.title}})]),_c('div',{staticClass:"tour-card-text-container"},[_c('h3',{staticClass:"tour-card-title"},[_vm._v(" "+_vm._s(tour.title)+" · "+_vm._s(_vm.lang === 'fr' ? tour.destination_fr : tour.destination)+" ")]),_c('div',{staticClass:"tour-card-rating"},[_c('Rating',{attrs:{"size":"xs","is-static":true,"rating":tour.partner.reviews.rating}}),_c('span',[_vm._v(" "+_vm._s(_vm.$tc( "reviews.n_reviews", tour.partner.reviews.count ))+" ")])],1),_c('p',{staticClass:"tour-card-details"},[_c('span',{staticClass:"tour-card-price"},[_vm._v(_vm._s(_vm.$t("common.from"))+" "+_vm._s(_vm._f("currency")(tour.shared_room_price, tour.currency, _vm.getCurrency, _vm.rates[ `${tour.currency}_${_vm.getCurrency}` ] )))]),_vm._v(" · "),_c('span',{staticClass:"tour-card-duration"},[_vm._v(_vm._s(_vm.$tc("offer.days", tour.duration)))])]),_c('span',{staticClass:"tour-card-agency"},[_vm._v(" "+_vm._s(_vm.$t("common.offered_by", {agency: _vm.setGenitive(tour.partner.first_name)}))+" ")])])])}),1):_vm._e()],1)]),_c('Footer',{staticClass:"pt-32"}),_c('BottomBar',{staticClass:"layout-bar"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour--skeleton"},[_c('div',{staticClass:"tour--skeleton--img"}),_c('div',{staticClass:"tour--skeleton--title"}),_c('div',{staticClass:"tour--skeleton--review"}),_c('div',{staticClass:"tour--skeleton--price"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour--skeleton"},[_c('div',{staticClass:"tour--skeleton--img"}),_c('div',{staticClass:"tour--skeleton--title"}),_c('div',{staticClass:"tour--skeleton--review"}),_c('div',{staticClass:"tour--skeleton--price"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour--skeleton"},[_c('div',{staticClass:"tour--skeleton--img"}),_c('div',{staticClass:"tour--skeleton--title"}),_c('div',{staticClass:"tour--skeleton--review"}),_c('div',{staticClass:"tour--skeleton--price"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour--skeleton"},[_c('div',{staticClass:"tour--skeleton--img"}),_c('div',{staticClass:"tour--skeleton--title"}),_c('div',{staticClass:"tour--skeleton--review"}),_c('div',{staticClass:"tour--skeleton--price"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour--skeleton"},[_c('div',{staticClass:"tour--skeleton--img"}),_c('div',{staticClass:"tour--skeleton--title"}),_c('div',{staticClass:"tour--skeleton--review"}),_c('div',{staticClass:"tour--skeleton--price"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour--skeleton"},[_c('div',{staticClass:"tour--skeleton--img"}),_c('div',{staticClass:"tour--skeleton--title"}),_c('div',{staticClass:"tour--skeleton--review"}),_c('div',{staticClass:"tour--skeleton--price"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour--skeleton"},[_c('div',{staticClass:"tour--skeleton--img"}),_c('div',{staticClass:"tour--skeleton--title"}),_c('div',{staticClass:"tour--skeleton--review"}),_c('div',{staticClass:"tour--skeleton--price"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour--skeleton"},[_c('div',{staticClass:"tour--skeleton--img"}),_c('div',{staticClass:"tour--skeleton--title"}),_c('div',{staticClass:"tour--skeleton--review"}),_c('div',{staticClass:"tour--skeleton--price"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour--skeleton"},[_c('div',{staticClass:"tour--skeleton--img"}),_c('div',{staticClass:"tour--skeleton--title"}),_c('div',{staticClass:"tour--skeleton--review"}),_c('div',{staticClass:"tour--skeleton--price"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour--skeleton"},[_c('div',{staticClass:"tour--skeleton--img"}),_c('div',{staticClass:"tour--skeleton--title"}),_c('div',{staticClass:"tour--skeleton--review"}),_c('div',{staticClass:"tour--skeleton--price"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour--skeleton"},[_c('div',{staticClass:"tour--skeleton--img"}),_c('div',{staticClass:"tour--skeleton--title"}),_c('div',{staticClass:"tour--skeleton--review"}),_c('div',{staticClass:"tour--skeleton--price"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour--skeleton"},[_c('div',{staticClass:"tour--skeleton--img"}),_c('div',{staticClass:"tour--skeleton--title"}),_c('div',{staticClass:"tour--skeleton--review"}),_c('div',{staticClass:"tour--skeleton--price"})])
}]

export { render, staticRenderFns }